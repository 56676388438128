// eslint-disable
export const yamapInit = function () {
  if (!document || !document.getElementById('yandexMapYa')) {
    return
  }

  // eslint-disable-next-line no-undef
  const map1 = new ymaps.Map('yandexMapYa', {
    center: [55.730706, 37.612023],
    zoom: 16
  })
  // eslint-disable-next-line no-undef
  const balloon1 = new ymaps.Placemark(
    [55.73218, 37.611167],
    {
      balloonContentHeader: 'Свадебный салон Love Forever',
      balloonContentBody:
        "Москва, ул. Большая Якиманка, 50<br /><br /><table><tr><td><img src='/img/icon-phone.png' alingn='' width='14px' height='14px' /></td><td>&nbsp;<address class='ancillary_item ancillary_phone ancillary_map'><span>Салон <a href=\"tel:84956451908\">8 495 645 19 08</a></span>" +
        '              <br />' +
        "              <span>Ателье <a href=\"tel:89168204497\">8 916 820 44 97</a></span></address></td></tr><tr><td><img src='/img/icon-clock.png' width='14px' height='14px' /></td><td>&nbsp;10:00 - 22:00</td></tr></table>",
      balloonContentFooter: "<a href='/yakimanka-route.pdf' target='_blank'>Посмотреть подробный маршрут</a>",
      hintContent: 'Посмотреть контактные данные'
    },
    {
      iconLayout: 'default#image',
      iconImageHref: '/img/place-marker.png',
      iconImageSize: [42, 44],
      iconImageOffset: [-14, -42]
    }
  )

  // eslint-disable-next-line no-undef
  const path1 = new ymaps.Polyline(
    [
      [55.7293, 37.611565],
      [55.729625, 37.611522],
      [55.729885, 37.611768],
      [55.732361, 37.611849],
      [55.732346, 37.611355]
    ],
    {
      balloonContent: 'Путь от метро Октябрьская'
    },
    {
      balloonCloseButton: false,
      strokeColor: '#f366c4',
      strokeWidth: 3
    }
  )

  map1.controls.add('zoomControl', { left: 5, top: 5 })
  map1.geoObjects
    .add(balloon1)
    .add(path1)
    // .add(path2)
  map1.behaviors.disable('scrollZoom')
  balloon1.balloon.open()
  // map.setCenter('55.623535, 37.857816');
}
