import { loadYmap } from 'vue-yandex-maps'
import { yamapInit } from '../js/yamap'

export default {
  mounted () {
    // eslint-disable-next-line
    if (window && window.hasOwnProperty('ymaps')) {
      // eslint-disable-next-line no-undef
      ymaps.ready(yamapInit)
    } else {
      loadYmap().then(yamapInit)
    }
  }
}
