<template>
  <section class="tabs main-page">
    <div class="wrapper_map wrapper_map__extended">
      <div class="wrapper_map_inner">
        <div class="parking">
          <div class="sign">
            P
          </div>
          Бесплатно под шлагбаум
        </div>

        <div id="yandexMapYa" style="width:950px; height:460px" />
      </div>
      <div class="wrapper_map_badge" />
    </div>
  </section>
</template>

<script>
import maps from '../assets/mixins/maps'

export default {
  name: 'MapSection',
  mixins: [maps]
}
</script>

<style></style>
